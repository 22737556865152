export const BC_CA = [
    '2024/11/11',
    '2024/12/25',
    '2024/12/26',
    '2025/1/1',
    '2025/2/17',
    '2025/3/14',
    '2025/4/18',
    '2025/5/19',
    '2025/5/23',
    '2025/6/19',
    '2025/6/20',
    '2025/7/1',
    '2025/8/4',
    '2025/8/29',
    '2025/9/1',
    '2025/9/30',
    '2025/10/13',
    '2025/11/11',
    '2025/12/25',
    '2025/12/26',
  ];